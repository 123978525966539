import React from "react";

const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white">
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">About</p>
        </div>
        <div className="pb-4">
          <p className="text-2xl font-bold inline border-b-2 border-gray-500">Education</p>
        </div>

        <div className="rounded-lg shadow-md shadow-white-500 card m-2 p-4 overflow-y-auto">
          <div>
            <p className="text-md font-bold inline">Hacktiv8</p>
            <br />
            <p className="text-md font-bold inline">Full Stack JavaScript Immersive</p>
            <br />
            <p className="text-md inline font-semibold text-gray-400">Jakarta</p>
            <br />
            <p className="text-sm inline font-semibold text-gray-400">November 2022 - February 2023</p>
            <br />
            <p className="text-sm inline font-semibold text-gray-400">Graduation status: Graduates</p>
            <br />
            <p className="text-sm inline font-semibold text-gray-400">Score: 81.50 / 100</p> <br /> <br />
            <p className="text-sm inline font-semibold text-gray-400">
              Learned how to develop a website or mobile app using JavaScript programming language with several framework such as React js, React native, and Vuejs for client UI, also learned how to create a server with Express or Apollo
              and manage it with Sequelize ORM or graphQL. I created an E-commerce website called Wedding One for my final project.
            </p>
          </div>
          <div className="pt-4">
            <p className="text-md font-bold inline">Gadjah Mada University</p>
            <br />
            <p className="text-md font-bold inline">Archaeology</p>
            <br />
            <p className="text-md inline font-semibold text-gray-400">Yogyakarta</p>
            <br />
            <p className="text-sm inline font-semibold text-gray-400">July 2012 - July 2019</p>
            <br />
            <p className="text-sm inline font-semibold text-gray-400">GPA: 3.10 / 4.00</p> <br /> <br />
            <p className="text-sm inline font-semibold text-gray-400">Undergraduate Thesis:</p> <br />
            <p className="text-sm inline font-semibold text-gray-400">"Fire Disaster Risk Reduction Measures on Traditional Buildings at Kotagede Cultural Heritage Area".</p>
          </div>
        </div>

        <div className="mt-8 pb-4">
          <p className="text-2xl font-bold inline border-b-2 border-gray-500">Work Experience</p>
        </div>
        <div className="rounded-lg shadow-md shadow-white-500 card m-2 p-4 overflow-y-auto">
        <p className="text-md font-bold inline">PT Visi Global Teknologi</p>
          <br />
          <p className="text-md font-bold inline">Fullstack Developer</p>
          <br />
          <p className="text-md inline font-semibold text-gray-400">IT Consultant</p>
          <br />
          <p className="text-md inline font-semibold text-gray-400">Jakarta</p>
          <br />
            <p className="text-sm inline font-semibold text-gray-400">Juli 2023 -Present</p>
          <br />
          <br />
          <ul className="list-disc text-sm font-semibold text-gray-400 pl-4">
            <li>Developing Front End website architecture using Next JS or React JS and also ensuring the web design features works in responsive media.</li>
            <li>Slicing UI design from Figma and creating HTML elements code using CSS Framework such as Tailwind, Bootstrap, or MaterialUI.</li>
            <li>Designing and developing APIs in Back End for website applications using PHP Laravel Framework and PostgreSQL or MySQL for server databases.</li>
          </ul>
          <div className="pt-8">
            <p className="text-md font-bold inline">PT Mitra Multindo Perkasa</p>
            <br />
            <p className="text-md font-bold inline">Business Development</p>
            <br />
            <p className="text-md inline font-semibold text-gray-400">Rental, Repair, and Supplier of Heavy Equipment.</p>
            <br />
            <p className="text-md inline font-semibold text-gray-400">Tangerang</p>
            <br />
            <p className="text-sm inline font-semibold text-gray-400">October 2021 - September 2022</p>
            <br />
            <br />
            <ul className="list-disc text-sm font-semibold text-gray-400 pl-4">
              <li>Drew up a strategy and plan for development of the company projects.</li>
              <li>Performed market research by making up analysis between customer needs and market situations to find opportunities for the company development project by using Ms. Excel.</li>
              <li>Generated sales activity for the company by obtaining new customers and projects. Targeted 100 million IDR revenue per month for the company.</li>
              <li>Led the project and synergized with other divisions to meet customer needs and ensured good relationships with customers.</li>
            </ul>
          </div>
          <div className="pt-8">
            <p className="text-md font-bold inline">PT Gaya Makmur Mobil FAW Trucks</p>
            <br />
            <p className="text-md font-bold inline">Business Consultant</p>
            <br />
            <p className="text-md inline font-semibold text-gray-400">Sole Distributor of First Automobile Works Truck</p>
            <br />
            <p className="text-md inline font-semibold text-gray-400">Jakarta</p>
            <br />
            <p className="text-sm inline font-semibold text-gray-400">December 2019 - October 2021</p>
            <br />
            <br />
            <ul className="list-disc text-sm font-semibold text-gray-400 pl-4">
              <li>Delivered informative presentation and quotation to generate sales activities of PT. Gaya Makmur Mobil product (FAW Truck). Targeted 5 customer calls or visits per day and 3 billion IDR revenue per year.</li>
              <li>Managed and developed customer database by Identifying customer needs and characteristics by using Bitrix application.</li>
              <li>Created a sales plan and sales report regularly based on active prospects.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
