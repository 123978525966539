import React from "react";
import HeroImg from "../assets/IMG_5167.jpeg";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FaFileDownload } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from "react-scroll";
import { FaGithub, FaLinkedin } from "react-icons/fa";

const Home = () => {
  const links= [
    {
      id: 1,
      child: (
        <>
          <FaLinkedin size={30} />
        </>
      ),
      href: "https://www.linkedin.com/in/ibnu-syadzali-67690218b/",
    },
    {
      id: 2,
      child: (
        <>
          <FaGithub size={30} />
        </>
      ),
      href: "https://github.com/ibnusyadzali",
    },
    {
      id: 3,
      child: (
        <>
          <HiOutlineMail size={30} />
        </>
      ),
      href: "mailto:ibnusyadzali@gmail.com",
    },
  ]
  return (
    <div name="home" className="h-screen w-full bg-gradient-to-b from-black to-gray-800">
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div className="flex flex-col justify-center h-full">
          <h2 className="text-4xl sm:text-6xl font-bold text-white">I'm a Full Stack Developer</h2>
          <p className="text-gray-500 py-4 max-w-md">
            My name is <span className="text-white font-semibold">Ibnu Syadzali</span>, an alumni of Hacktiv8 Indonesia. I am interested in IT because I aspire to become a developer and an expert in the field of machine learning. I am able to work as Back End or Front End but my best skills tend to be Front End. I have possessed skills on technical stacks such as React JS, Node JS, PHP Laravel, etc.
          </p>
          <div className="flex flex-row">
            <Link to="portfolio" smooth duration={500} className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer">
              Portfolio
              <span className="group-hover:rotate-90 duration-300">
                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
              </span>
            </Link>

            <a href="/CV_Ibnu_Syadzali.pdf" className="group ml-4 text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-blue-500 to-cyan-500 cursor-pointer lg:hidden" download={true}>
              My CV
              <span className=" ml-2 group-hover:scale-125 duration-300">
                <FaFileDownload size={15} className="ml-1" />
              </span>
            </a>
          </div>
          <div className="flex flex-row text-white gap-8 mt-4 lg:hidden">
            {links.map(({id, child, href}) => (
            <a 
            key={id}
            href={href}
            className="hover:scale-125 duration-300"
            target="_blank" rel="noreferrer">
              {child}
            </a>
            ))}
          </div>
        </div>
        <div className="">
          <img src={HeroImg} alt="my profile" className="rounded-2xl mx-auto w-2/3 md:w-full" />
        </div>
      </div>
    </div>
  );
};

export default Home;
