import React from "react";
import CleanMySneakers from "../assets/portfolio/CleanMySneakers.png";
import WeddingOne from "../assets/portfolio/wedding-one.png";
import Watchoo from "../assets/portfolio/Screen Shot 2023-06-09 at 09.23.39.png"

const Portfolio = () => {
  const portfolios = [
    {
      id: 1,
      src: Watchoo,
      href: "https://watchoo.ibnusyadzali.com/",
      name: "Watchoo"
    },
    {
      id: 2,
      src: CleanMySneakers,
      href: "https://cleanmyshoes.ibnusyadzali.com/",
      name: "Clean My Shoes"
    },
    {
      id: 3,
      src: "https://res.cloudinary.com/dnh89xvo5/image/upload/v1673958687/p3_challenge_1/Hackshoes-removebg-preview_v15sdm.png",
      href: "https://hackshoes.ibnusyadzali.com/",
      name: "Hackshoes"
    },
    {
      id: 4,
      src: WeddingOne,
      href: "https://wedding-one.ibnusyadzali.com/",
      name: "Wedding-One"
    },
  ];

  return (
    <div name="portfolio" className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen">
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">Portfolio</p>
          <p className="pt-6">Check out some of my work right here:</p>
        </div>

        <div className="grid p-4 overflow-y-auto sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-4">
          {portfolios.map(({ id, src, href, name }) => (
            <div key={id} className="shadow-md shadow-gray-600 rounded-lg flex flex-col justify-center items-center">
              <img src={src} alt="" className="portoImg mt-1 rounded-md duration-200 hover:scale-105" />
              <div className="flex items-center justify-center flex-col">
                <p className="capitalize pt-3 text-lg font-bold">{name}</p>
                <a href={href} className=" px-6 pb-3 m-4 duration-200 hover:scale-105" target="_blank" rel="noreferrer">
                  Demo
                </a>
              </div>
            </div>
          ))}
        </div>
        <p className="pt-2">Notes: Some of those projects are not responsive website. please open demo in desktop mode.</p>
      </div>
    </div>
  );
};

export default Portfolio;
