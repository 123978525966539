import React from "react";

//import png
import bootstrap from "../assets/skills/bootstrap.png";
import css from "../assets/skills/css.png";
import docker from "../assets/skills/docker.png";
import express from "../assets/skills/express.png";
import github from "../assets/skills/github.png";
import graphql from "../assets/skills/graphql.png";
import grafana from "../assets/skills/grafana.png";
import html from "../assets/skills/html.png";
import javascript from "../assets/skills/javascript.png";
import jest from "../assets/skills/jest.png";
import jquery from "../assets/skills/jquery.png";
import jwt from "../assets/skills/jwt.png";
import laravel from "../assets/skills/laravel.png";
import mariadb from "../assets/skills/mariadb.png";
import mongoDB from "../assets/skills/mongoDB.png";
import mui from "../assets/skills/mui.png";
import mysql from "../assets/skills/mysql.png";
import nextjs from "../assets/skills/nextjs.png";
import node from "../assets/skills/node.png";
import phalcon from "../assets/skills/phalcon.png";
import php from "../assets/skills/php.png";
import pinia from "../assets/skills/pinia.png";
import postgre from "../assets/skills/postgre.png";
import reactImage from "../assets/skills/react.png";
import redis from "../assets/skills/redis.png";
import redux from "../assets/skills/redux.png";
import sequelize from "../assets/skills/sequelize.png";
import tailwind from "../assets/skills/tailwind.png";
import vue from "../assets/skills/vue.png";

const Skills = () => {
  const techs = [
    {
      id: 1,
      src: javascript,
      title: "JavaScript",
      style: "shadow-yellow-500",
    },
    {
      id: 2,
      src: php,
      title: "PHP",
      style: "shadow-blue-300 pt-7",
    },
    {
      id: 3,
      src: html,
      title: "HTML",
      style: "shadow-orange-500",
    },
    {
      id: 4,
      src: css,
      title: "CSS",
      style: "shadow-blue-500",
    },
    {
      id: 5,
      src: reactImage,
      title: "React",
      style: "shadow-sky-600",
    },
    {
      id: 6,
      src: nextjs,
      title: "Next JS",
      style: "shadow-gray-400",
      logo: "h-20",
    },
    {
      id: 7,
      src: redux,
      title: "Redux",
      style: "shadow-violet-600",
    },
    {
      id: 8,
      src: vue,
      title: "Vue",
      style: "shadow-green-400",
    },
    {
      id: 9,
      src: pinia,
      title: "Pinia",
      style: "shadow-yellow-500",
      logo: "h-20 w-12",
    },
    {
      id: 10,
      src: grafana,
      title: "Grafana",
      style: "shadow-orange-500",
      logo: "h-20",
    },
    {
      id: 11,
      src: mui,
      title: "Material UI",
      style: "shadow-blue-600 pt-6",
    },
    {
      id: 12,
      src: tailwind,
      title: "Tailwind",
      style: "shadow-sky-400",
    },
    {
      id: 13,
      src: bootstrap,
      title: "Bootstrap",
      style: "shadow-violet-400",
    },
    {
      id: 14,
      src: docker,
      title: "Docker",
      style: "shadow-sky-400 pt-7",
    },
    {
      id: 15,
      src: postgre,
      title: "Postgre",
      style: "shadow-blue-400",
    },
    {
      id: 16,
      src: mysql,
      title: "MySQL",
      style: "shadow-blue-400",
      logo: "w-1/2 mt-2"
    },
    {
      id: 17,
      src: mongoDB,
      title: "MongoDB",
      style: "shadow-green-400 pt-10",
      logo: "w-1/2 mb-6"
    },
    {
      id: 18,
      src: mariadb,
      title: "MariaDB",
      style: "shadow-blue-400",
      logo: "mt-4"
    },
    {
      id: 19,
      src: redis,
      title: "Redis",
      style: "shadow-red-600",
    },
    {
      id: 20,
      src: node,
      title: "Node JS",
      style: "shadow-green-300",
    },
    {
      id: 21,
      src: express,
      title: "Express JS",
      style: "shadow-green-300",
      logo: "w-1/2 mt-8 mb-8"
    },
    {
      id: 22,
      src: phalcon,
      title: "Phalcon PHP",
      style: "shadow-green-300",
    },
    {
      id: 23,
      src: laravel,
      title: "Laravel",
      style: "shadow-red-600",
    },
    {
      id: 24,
      src: jquery,
      title: "JQuery",
      style: "shadow-blue-600",
    },
    {
      id: 25,
      src: graphql,
      title: "GraphQL",
      style: "shadow-pink-400",
    },
    {
      id: 26,
      src: sequelize,
      title: "Sequelize",
      style: "shadow-sky-600",
    },
    {
      id: 27,
      src: jest,
      title: "Jest",
      style: "shadow-red-400",
    },
    {
      id: 28,
      src: jwt,
      title: "Json Web Token",
      style: "shadow-gray-400",
      logo: "w-1/2"
    },
    {
      id: 29,
      src: github,
      title: "GitHub",
      style: "shadow-gray-400",
    },
  ];

  return (
    <div name="skills" className="bg-gradient-to-b from-gray-800 to-black w-full h-screen">
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
          <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">Skills</p>
          <p className="py-6">These are the technologies I've worked with</p>
        </div>

        <div className="w-full grid grid-cols-2 overflow-y-auto sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-4">
          {techs.map(({ id, src, title, style, logo }) => (
            <div key={id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
              <img src={src} alt="" className={`w-20 mx-auto ${logo}`} />
              <p className="mt-4">{title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
